import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WidgetsModule } from "./widgets";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { environment } from "@root/src/environments/environment";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { I18nModule, translateLoaderFactory } from "@root/src/app/i18n/i18n.module";
import { AnalyticsModule } from "@cdk/analytics/analytics.module";
import { AcceptLanguageInterceptor } from "./interceptors";
import { CookieService } from "ngx-cookie-service";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      WidgetsModule,
      MatDialogModule,
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: translateLoaderFactory,
            deps: [HttpClient]
         },
         isolate: false,
         extend: true
      }),
      I18nModule,
      AnalyticsModule.forRoot({
         googleAnalyticsTrackingCode: environment.googleAnalyticsTrackingCode,
         googleTagManagerScriptUrl: environment.googleTagManagerScriptUrl
      })
   ],
   providers: [
      CookieService,
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AcceptLanguageInterceptor,
         multi: true
      }
   ],
   bootstrap: [AppComponent]
})
export class AppModule {}
