import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { Subject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class PaginatorLocalization implements MatPaginatorIntl, OnDestroy {
   changes = new Subject<void>();
   private subs: Subscription[] = [];

   constructor(private translate: TranslateService) {
      this.updateTranslations();
      this.subs.push(this.translate.onLangChange.subscribe(() => this.updateTranslations()));
   }

   private updateTranslations() {
      this.itemsPerPageLabel = this.translate.instant(this.itemsPerPageId);
      this.firstPageLabel = this.translate.instant(this.firstPageId);
      this.lastPageLabel = this.translate.instant(this.lastPageId);
      this.nextPageLabel = this.translate.instant(this.nextPageId);
      this.previousPageLabel = this.translate.instant(this.previousPageId);
      this.changes.next();
   }

   getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
         return this.translate.instant(`Page x of y`, { current: 1, max: 1 });
      }
      const amountPages = Math.ceil(length / pageSize);
      return this.translate.instant(`Page x of y`, { current: page + 1, max: amountPages });
   }

   itemsPerPageLabel = '';
   firstPageLabel = '';
   lastPageLabel = '';
   nextPageLabel = '';
   previousPageLabel = '';

   private itemsPerPageId = 'Items per page:';
   private firstPageId = 'First page';
   private lastPageId = 'Last page';
   private nextPageId = 'Next page';
   private previousPageId = 'Previous page';

   ngOnDestroy(): void {
      this.subs.forEach(s => s.unsubscribe());
   }
}
